<template>
  <v-app id="inspire" style="background: #fafafa">
    <v-btn
      v-show="mini"
      @click.stop="drawer = !drawer"
      icon
      class="my-2 mx-4"
      fab
      small
    >
      <v-avatar size="32">
        <img :src="require('@/assets/images/logo.png')" alt="logo" />
      </v-avatar>
      <v-icon small>mdi-menu</v-icon>
    </v-btn>

    <v-btn
      disabled
      top
      center
      text
      background="transparent"
      style="text-transform: unset"
    >
      <v-subheader v-show="mini" class="text-h5">{{
        titles[current]
      }}</v-subheader></v-btn
    >

    <v-navigation-drawer
      v-model="drawer"
      style="z-index: 9999"
      :permanent="!mini"
      :absolute="mini"
      app
      width="300"
      :height="$vuetify.breakpoint.name == 'xs' ? '93%' : '100%'"
    >
      <v-list-item>
        <v-list-item-content>
          <v-row align="center" justify="center" :class="mini ? 'px-3' : ''">
            <a target="blank" href="https://www.privately.eu">
              <img
                :src="require('@/assets/images/privlogo.png')"
                :width="mini ? 130 : 200"
            /></a>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-row justify="center">
            <div style="text-transform: unset !important; font-size: 13px">
              <v-icon>mdi-account</v-icon>{{ user }}
            </div>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <v-list nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.link"
          :disabled="item.disabled"
        >
          <v-list-item-icon>
            <v-icon :disabled="item.disabled">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <br />

        <v-list-item v-if="false">
          <v-btn
            v-if="!mini"
            dark
            x-large
            block
            to="/plans"
            class="rounded-0"
            color="#18739d"
            >UPGRADE</v-btn
          >
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>
        <v-list-item link href="mailto:contact@privately.eu" target="_blank">
          <v-list-item-content>
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#18739d">mdi-help-circle-outline </v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-format-horizontal-align-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-container fluid><slot /></v-container>
  </v-app>
</template>

<script>
import store from "@/store";

export default {
  props: {
    current: String,
  },
  created() {
    this.user = store.getters.StateRetailUser;

    console.log(this.user);
    console.log(this.current);
  },

  methods: {
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push("/retail-login");
    },
  },
  computed: {
    mini() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  data() {
    return {
      show_upgrade: true,
      drawer: false,

      items: [
        {
          title: "List of Licences",
          icon: "mdi-qrcode",
          link: "/retail-selfservice-licencelist",
          disabled: false,
        },
        {
          title: "Manage Subscriptions",
          icon: "mdi-credit-card",
          link: "/retail-subscriptions",
          disabled: false,
        },
      ],
      titles: {
        licenceList: "List of Licences",
        manageSubscriptions: "Manage Subscriptions",
      },
      right: null,
      user: "",
      isSelfService: false,
      docs_route: "/home",
      contact_route: "https://www.privately.eu/#contact",
    };
  },
};
</script>
<template>
  <base-layout current="activatelicence">
    <v-container>
      <v-card :loading="isLoading" class="card-main" elevation="0">
        <v-container fill-height fluid>
          <v-row class="ma-4" justify="center" align="center">
            <v-col>
              <EstimationCard
                v-if="!isLoading"
                metric-title="Total number of licences"
                :metricValue="numberOfLicences"
            /></v-col>
            <v-col>
              <EstimationCard
                v-if="!isLoading"
                metric-title="Active"
                :metricValue="numberOfActivatedLicences"
            /></v-col>

            <v-col>
              <EstimationCard
                v-if="!isLoading"
                metric-title="Inactive"
                :metricValue="numberOfInactiveLicences"
            /></v-col> </v-row
        ></v-container>

        <v-row class="pa-4">
          <v-col v-for="item in licenceList" :key="item['licence_code']">
            <QRCard
              :size="100"
              :activationToken="item['deeplink_data']"
              level="H"
              :licenceCode="item['licence_code']"
              :isActivated="item['activated_at_utc']"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import store from "@/store";
import QRCard from "@/components/cards/QRCard.vue";
import { mapActions } from "vuex";
import EstimationCard from "@/components/cards/EstimationCard.vue";

export default {
  beforeMount() {
    this.userData = store.getters.StateUserData;
    this.apiKey = store.getters.StateSessionId;
    this.secretKey = store.getters.StateSessionPassword;
    this.sessionDetails = store.getters.StateSessionDetails;
    console.log(this.sessionDetails);
    console.log(this.userData);
  },
  mounted() {
    this.isLoading = true;
    this.ListLicences({
      session_id: this.apiKey,
      session_password: this.secretKey,
    }).then((response) => {
      console.log(response);
      if (response.msg == "OK") {
        this.licenceList = response.licences;
        this.numberOfActivatedLicences = this.licenceList.filter(
          (licence) => licence.activated_at_utc != false
        ).length;
        this.numberOfLicences = this.licenceList.length;
        this.numberOfInactiveLicences =
          this.numberOfLicences - this.numberOfActivatedLicences;

        console.log("licencesssssss:", this.licenceList);
        this.isLoading = false;
      }
    });
  },
  components: {
    BaseLayout,
    QRCard,
    EstimationCard,
  },
  methods: {
    ...mapActions(["ListLicences"]),
    // copyKey(key) {
    //   let keyToCopy = document.querySelector("#" + key);
    //   keyToCopy.setAttribute("type", "text");
    //   keyToCopy.select();

    //   try {
    //     document.execCommand("copy");
    //     if (key == "secret-key") {
    //       keyToCopy.setAttribute("type", "password");
    //     }
    //     this.snackbar = true;
    //   } catch (err) {
    //     console.log(err);
    //   }

    //   window.getSelection().removeAllRanges();
    // },
  },
  data() {
    return {
      apiKey: "",
      secretKey: "",
      hover: null,
      userData: "",
      isLoading: false,
      licenceList: [],
      numberOfLicences: 0,
      numberOfActivatedLicences: 0,
      numberOfInactiveLicences: 0,
      item: null,
      showSecret: false,
      snackbar: false,
      text: "Copied!",
      timeout: 2000,
      sessionDetails: "",
    };
  },
};
</script>
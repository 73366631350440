<template>
  <base-layout current="profile">
    <v-container fluid>
      <v-card class="card-main" elevation="0">
        <div>
          <v-row class="px-2 py-4"
            ><v-card elevation="0" max-width="800" color="transparent">
              <v-list-item>
                <v-list-item-content>
                  <v-subheader class="text-h5">Delete Your Account</v-subheader>
                </v-list-item-content>
              </v-list-item>
              <div class="px-4">
                <v-alert text color="warning" type="warning">
                  <h3 class="text-h5">Warning</h3>
                  <div>
                    Are you sure you want to delete this account? All data,
                    including active subscriptions, will be lost
                  </div>

                  <v-row align="center" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="shrink">
                      <v-btn color="error" @click="deleteAccount()">
                        Yes, delete account
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>
            </v-card></v-row
          >
        </div>
      </v-card>
    </v-container>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import store from "@/store";
import { mapActions } from "vuex";

export default {
  beforeMount() {
    this.sessionId = store.getters.StateSessionId;
  },
  components: {
    BaseLayout,
  },
  methods: {
    ...mapActions(["DeleteAccount"]),

    async deleteAccount() {
      try {
        await this.DeleteAccount(this.sessionId);
        await this.$store.dispatch("LogOut");
        this.$router.push("/login");
      } catch (error) {
        console.log(error);
      }
    },
  },
  data() {
    return {
      userData: "",
      accountDeleteRoute: "/account/delete",
      sessionId: "",
    };
  },
};
</script>
<template>
  <base-layout current="activatelicence">
    <v-container>
      <v-card class="card-main" elevation="0">
        <v-container fill-height fluid>
          <v-row class="ma-4" justify="center" align="center">
            <v-col>
              <EstimationCard
                :loading="isLoading"
                metric-title="Total number of licences"
                :metricValue="getTotalNumberOfLicences"
            /></v-col>
            <v-col>
              <EstimationCard
                :loading="isLoading"
                metric-title="Active"
                :metricValue="numberOfActivatedLicences"
            /></v-col>

            <v-col>
              <EstimationCard
                :loading="isLoading"
                metric-title="Inactive"
                :metricValue="numberOfInactiveLicences"
            /></v-col> </v-row
        ></v-container>

        <v-container>
          <v-data-table
            :loading="isLoading"
            loading-text="Loading... Please wait"
            :headers="getHeadersDynamically"
            :items="flattenedList"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-toolbar-title class="text-h5 font-weight-medium"
                    >List of licences
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-text-field
                    style="max-width: 450px"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    :options.sync="options"
                    :server-items-length="totalNumberOfLicences"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="reset-text-transform"
                    color="green"
                    dark
                    @click="dialogForLicenceGeneration = true"
                    >Generate new licences <v-icon> mdi-plus</v-icon>
                  </v-btn></v-row
                >
              </v-toolbar>
            </template>

            <template v-slot:[`item.activated_at_utc`]="{ item }">
              <div v-if="item.deactivated_at_utc == null">
                <v-tooltip
                  v-if="
                    item.activated_at_utc && item.deactivated_at_utc == null
                  "
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-chip
                        :color="!item.activated_at_utc ? 'blue' : 'green'"
                        dark
                      >
                        {{
                          item.activated_at_utc ? "Activated" : "Not activated"
                        }}
                      </v-chip></span
                    >
                  </template>
                  <span>at {{ item.activated_at_utc }}</span>
                </v-tooltip>
                <v-chip
                  v-else
                  :color="!item.activated_at_utc ? 'blue' : 'green'"
                  dark
                >
                  {{ item.activated_at_utc ? "Activated" : "Not activated" }}
                </v-chip>
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-chip color="red" dark> Deactivated </v-chip></span
                    >
                  </template>
                  <span>at {{ item.deactivated_at_utc }}</span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:[`item.deeplink_data`]="{ item }">
              <div style="display: flex; align-items: center">
                <v-btn
                  class="reset-text-transform mt-2"
                  color="black"
                  plain
                  :disabled="item.activated_at_utc != false"
                  @click="onQRClicked(item)"
                  text
                >
                  <v-icon dense>mdi-qrcode</v-icon>
                </v-btn>
                <v-divider inset vertical></v-divider>

                <v-btn
                  class="reset-text-transform mt-2"
                  color="black"
                  :disabled="item.activated_at_utc != false"
                  plain
                  :href="
                    'https://developer.privately.eu/retail-app-activation/' +
                    item.deeplink_data
                  "
                  target="_blank"
                  text
                >
                  <v-icon dense>mdi-open-in-new</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <v-snackbar
            :timeout="snackbarTimeout"
            :value="false"
            absolute
            top
            :color="!errorOccurred ? 'success' : 'red'"
            v-model="snackbar"
          >
            {{ text }}
            <template v-slot:action="{ attrs }">
              <v-btn
                class="reset-text-transform"
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-container>
      </v-card>
      <v-dialog v-model="dialogForQRCode" width="350">
        <v-card class="justify-center">
          <v-card-title class="subtitle-1 grey lighten-2">
            <span class="font-weight-bold">Licence code:&nbsp;</span>
            {{ selectedLicenceCode }} <br />
            <span
              style="
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
              "
              class="subtitle-1"
              >Please scan the QR code below to activate your
              <span class="font-weight-bold">AgeAI for Retail</span>
              licence</span
            >
          </v-card-title>
          <v-container fill-height>
            <v-row justify="center" align="center"
              ><v-col>
                <v-card-text>
                  <qrcode-vue
                    :value="
                      'https://developer.privately.eu/retail-app-activation/' +
                      selectedDeeplinkData
                    "
                    :size="300"
                    level="H"
                  /> </v-card-text
              ></v-col> </v-row
          ></v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogForLicenceGeneration" max-width="500px">
        <v-card :loading="loadingForLicenceGeneration">
          <v-card-title>
            <span class="text-h5">Generate new licences</span>
          </v-card-title>

          <v-card-text>
            <v-container
              ><v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="formInput.requested_by"
                      :rules="[(v) => !!v || 'This field is required']"
                      label="Requested by"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="formInput.subclient"
                      label="Subclient"
                      :rules="[(v) => !!v || 'This field is required']"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="formInput.location"
                      :rules="[(v) => !!v || 'This field is required']"
                      label="Location"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="formInput.billed_to_email"
                      outlined
                      dense
                      :rules="[
                        (v) => !!v || 'This field is required',
                        (v) => emailRegex.test(v) || 'Invalid Email address',
                      ]"
                      label="Billed to (email)"
                      required
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="formInput.quantity"
                      :rules="[
                        (v) => !!v || 'This field is required',
                        (v) =>
                          (v && v <= 50 && v >= 1) ||
                          'Quantity must be between 1 and 50',
                      ]"
                      label="Quantity"
                      type="number"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8" md="8">
                    <v-select
                      v-model="formInput.price_per_licence"
                      :items="priceList"
                      outlined
                      dense
                      item-text="priceName"
                      item-value="priceVal"
                      :rules="[(v) => !!v.priceVal || 'Please select a price']"
                      label="Price per licence"
                      return-object
                      single-line
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-radio-group
                      v-model="formInput.subscription_period"
                      required
                      :rules="[(v) => !!v || 'Please select a period']"
                      column
                    >
                      <template v-slot:label>
                        <div style="font-size: 16px">
                          Subscription Period: &nbsp;
                        </div>
                      </template>
                      <v-radio label="Monthly" value="month"></v-radio>
                      <v-radio
                        label="Yearly"
                        value="year"
                      ></v-radio> </v-radio-group
                  ></v-col>

                  <v-row
                    v-if="
                      formInput.quantity > 0 &&
                      formInput.quantity <= 50 &&
                      formInput.price_per_licence.priceVal &&
                      formInput.subscription_period != ''
                    "
                    align="center"
                  >
                    <v-col cols="6">
                      <v-subheader style="font-size: 16px"
                        >Estimated price:</v-subheader
                      >
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        readonly
                        :value="getEstimatedPrice"
                        prefix="$"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-col></v-col> </v-row
              ></v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="dialogForLicenceGeneration = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :loading="loadingForLicenceGeneration"
              color="green darken-1"
              dark
              @click="generateLicences"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import store from "@/store";
import QrcodeVue from "qrcode.vue";
import { mapActions } from "vuex";
import EstimationCard from "@/components/cards/EstimationCard.vue";

export default {
  beforeMount() {
    this.user = store.getters.StateUser;
    this.userData = store.getters.StateUserData;
    this.apiKey = store.getters.StateSessionId;
    this.secretKey = store.getters.StateSessionPassword;
    this.sessionDetails = store.getters.StateSessionDetails;
    console.log(this.user);
  },
  mounted() {
    this.init();
  },
  created() {},
  components: {
    BaseLayout,
    QrcodeVue,
    EstimationCard,
  },
  methods: {
    ...mapActions(["ListLicences", "GenerateLicences"]),
    init() {
      this.isLoading = true;
      this.flattenedList = [];
      this.licenceList = [];
      this.ListLicences({
        session_id: this.apiKey,
        session_password: this.secretKey,
      })
        .then((response) => {
          if (response.msg == "OK") {
            this.errorOccurred = false;
            this.licenceList = response.licences;
            this.numberOfActivatedLicences = this.licenceList.filter(
              (licence) => licence.activated_at_utc != false
            ).length;
            this.totalNumberOfLicences = this.licenceList.length;
            this.numberOfInactiveLicences =
              this.totalNumberOfLicences - this.numberOfActivatedLicences;
            for (let licence of this.licenceList) {
              this.flattenedList.push(this.flattenObject(licence));
            }
          }
        })
        .catch((error) => {
          console.log("error occurred: ", error);
          this.errorOccurred = true;
          this.text = error;
          this.snackbar = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    async generateLicences() {
      if (this.validate() === true) {
        this.loadingForLicenceGeneration = true;

        const generateLicencesPayload = {
          session_id: this.apiKey,
          session_password: this.secretKey,
          quantity: parseInt(this.formInput.quantity),
          requested_by: this.formInput.requested_by,
          subclient: this.formInput.subclient,
          location: this.formInput.location,
        };

        if (this.selfService) {
          generateLicencesPayload["billed_to_email"] =
            this.formInput.billed_to_email;
          generateLicencesPayload["price_per_licence"] = parseFloat(
            this.formInput.price_per_licence.priceVal
          );
          generateLicencesPayload["pricing_currency"] = "USD";
          generateLicencesPayload["reseller_email"] = this.user;
          generateLicencesPayload["subscription_settings"] = {
            subscription_period: this.formInput.subscription_period,
            baseDomain: this.baseDomain,
            env: this.baseDomain.includes("localhost") ? "test" : "prod",
          };
        }
        console.log(
          "GENERATE LICENCES REQUEST PAYLOAD",
          generateLicencesPayload
        );
        this.GenerateLicences(generateLicencesPayload)
          .then((response) => {
            if (response) {
              const currentLicenceListLength = this.licenceList.length;
              console.log("GENERATE LICENCES RESP:", response);
              if (
                !this.isObjectEmpty(response.data["billing_group_metadata"])
              ) {
                this.billing_group_id =
                  response.data["billing_group_metadata"]["billing_group_id"];
              }
              const pollForLicenceListUpdates = () => {
                this.ListLicences({
                  session_id: this.apiKey,
                  session_password: this.secretKey,
                })
                  .then((response) => {
                    if (response.msg === "OK") {
                      const updatedLicenceListLength = response.licences.length;
                      if (updatedLicenceListLength > currentLicenceListLength) {
                        this.licenceList = response.licences;
                        this.text = `${generateLicencesPayload.quantity} licence(s) have been generated successfully`;
                        this.snackbar = true;
                        this.loadingForLicenceGeneration = false;
                        this.dialogForLicenceGeneration = false;
                        this.init();
                      } else {
                        setTimeout(pollForLicenceListUpdates, 1000);
                      }
                    }
                  })
                  .catch((error) => {
                    console.error("Error occurred during polling: ", error);
                  });
              };
              pollForLicenceListUpdates();
            }
          })
          .catch((error) => {
            console.error("Error occurred: ", error);
            this.errorOccurred = true;
            this.text = "Error occurred: " + error;
            this.snackbar = true;
            this.loadingForLicenceGeneration = false;
          });
      } else {
        console.log("Validation failed");
      }
    },
    onQRClicked(item) {
      this.dialogForQRCode = true;
      this.selectedDeeplinkData = item["deeplink_data"];
      this.selectedLicenceCode = item["licence_code"];
      //start polling for activation
      this.pollForLicenceActivation();
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    pollForLicenceActivation() {
      const pollInterval = 2000;
      const maxPollAttempts = 15;

      let pollCount = 0;
      const poll = setInterval(() => {
        pollCount++;
        this.ListLicences({
          session_id: this.apiKey,
          session_password: this.secretKey,
        })
          .then((response) => {
            if (response.msg === "OK") {
              const updatedLicence = response.licences.find(
                (licence) => licence.licence_code === this.selectedLicenceCode
              );

              if (updatedLicence && updatedLicence.activated_at_utc) {
                clearInterval(poll);
                this.isQRScanned = false;
                this.dialogForQRCode = false;
                this.licenceList = response.licences;
                this.init();
                this.snackbar = true;
                this.text = `Licence with code ${this.selectedLicenceCode} has been activated successfully`;
              }

              if (pollCount >= maxPollAttempts || !this.dialogForQRCode) {
                clearInterval(poll);
                this.isQRScanned = false;
                this.dialogForQRCode = false;
              }
            }
          })
          .catch((error) => {
            clearInterval(poll);
            console.error("Error occurred during polling: ", error);
          });
      }, pollInterval);
    },
    flattenObject(obj) {
      const flattened = {};

      Object.keys(obj).forEach((key) => {
        const value = obj[key];

        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          Object.assign(flattened, this.flattenObject(value));
        } else {
          flattened[key] = value;
        }
      });

      return flattened;
    },
  },
  computed: {
    getTotalNumberOfLicences: function () {
      return this.totalNumberOfLicences;
    },
    getEstimatedPrice: function () {
      if (this.formInput.subscription_period === "month") {
        return (
          this.formInput.quantity * this.formInput.price_per_licence.priceVal +
          " / Month"
        );
      } else if (this.formInput.subscription_period === "year") {
        return (
          this.formInput.quantity *
            this.formInput.price_per_licence.priceVal *
            12 +
          " / Year"
        );
      } else return 0;
    },
    priceList: function () {
      let priceList = [
        { priceName: "$67.5 (10% discount)", priceVal: "67.5" },
        { priceName: "$56 (25% discount)", priceVal: "56" },
        { priceName: "$45 (40% discount)", priceVal: "45" },
        { priceName: "$37.5 (50% discount)", priceVal: "37.5" },
      ];

      if (this.formInput.quantity < 10) {
        priceList.pop();
      }
      return priceList;
    },
    getHeadersDynamically: function () {
      let headers = [
        {
          text: "Licence code",
          align: "start",
          sortable: false,
          value: "licence_code",
        },
        { text: "Created at", value: "licence_created_at_utc" },
        { text: "Status", value: "activated_at_utc" },
        { text: "Requested by", value: "requested_by" },
        { text: "Subclient", value: "subclient" },
        { text: "Location", value: "location" },
        { text: "QR Code | Deeplink", sortable: false, value: "deeplink_data" },
      ];

      if (this.selfService) {
        headers.pop();
        headers.push({ text: "Billed to", value: "billed_to_email" });
      }

      return headers;
    },
  },
  data() {
    return {
      // priceList: [
      //   { text: "$67.5 (10% discount)", value: "67.5" },
      //   { text: "$56 (25% discount)", value: "56" },
      //   { text: "$45 (40% discount)", value: "45" },
      // ],
      //select: { state: "Florida", abbr: "FL" },
      search: "",
      user: "",
      apiKey: "",
      emailRegex:
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      secretKey: "",
      options: {},
      userData: "",
      isLoading: false,
      billing_group_id: "",
      licenceList: [],
      numberOfLicences: 0,
      numberOfActivatedLicences: 0,
      numberOfInactiveLicences: 0,
      item: null,
      snackbar: false,
      text: " licence(s) have been generated successfully!",
      snackbarTimeout: 5000,
      sessionDetails: "",
      flattenedList: [],
      baseDomain: window.location.origin,
      dialogForQRCode: false,
      selectedLicenceCode: "",

      selectedDeeplinkData: "",
      //estimatedPrice: 0,
      errorOccurred: false,
      formInput: {
        quantity: "",
        requested_by: "",
        subclient: "",
        location: "",
        billed_to_email: "",
        price_per_licence: {},
        subscription_period: "",
        pricing_currency: "USD",
      },
      dialogForLicenceGeneration: false,
      loadingForLicenceGeneration: false,
      valid: true,
      totalNumberOfLicences: 0,
      selfService: true,
    };
  },
  watch: {
    options: {
      handler() {
        this.init();
      },
      deep: false,
    },
  },
};
</script>
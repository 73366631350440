<template>
  <retail-base-layout current="manageSubscriptions">
    <v-container fluid>
      <v-card class="card-main" elevation="0" :loading="isLoading">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Billing Group ID</th>
                <th class="text-left">Payment Status</th>
                <th class="text-left">Last Update</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in billingGroups" :key="item.billing_group_id">
                <td>{{ item.billing_group_id }}</td>
                <td>
                  {{
                    item.payment_info.payment_result
                      ? item.payment_info.payment_result
                      : "payment_not_made"
                  }}
                </td>
                <td>
                  <div v-if="item.payment_info && item.payment_info.updated_at">
                    {{ formatDate(item.payment_info.updated_at) }}
                  </div>
                </td>
                <td>
                  <v-btn
                    @click="
                      cancelSubscription(
                        item.payment_info.stripe_subscription_id
                      )
                    "
                    :loading="isCancelLoading"
                    v-if="
                      item.payment_info.payment_result &&
                      item.payment_info.payment_result ===
                        'payment_succeeded' &&
                      item.payment_info.stripe_customer_id &&
                      item.payment_info.stripe_subscription_id
                    "
                    >Cancel Subscription<template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template></v-btn
                  >
                  <div v-else></div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-snackbar
          timeout="2000"
          absolute
          right
          color="green"
          text
          v-model="snackbar"
        >
          {{ snackbarText }}
        </v-snackbar>
      </v-card>
    </v-container>
  </retail-base-layout>
</template>

<script>
import RetailBaseLayout from "@/layouts/RetailBaseLayout.vue";
import store from "@/store";
import axios from "axios";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  mounted() {
    if (store.getters.StateRetailData == null) {
      this.$router.push("/retail-login");
    } else {
      this.init();
    }
  },
  components: {
    RetailBaseLayout,
  },
  methods: {
    ...mapActions(["RetailLogin", "ListLicencesByBillingGroupId"]),
    async cancelSubscription(subscriptionId) {
      this.isCancelLoading = true;
      try {
        const payload = {
          subscriptionId: subscriptionId,
          env: this.baseDomain.includes("localhost") ? "test" : "prod",
        };

        const response = await axios.post(
          "https://hwlrelcyhh.execute-api.eu-west-1.amazonaws.com/default/retailapp-selfservice-stripe-subscriptionmanagement",
          payload
        );

        console.log("Subscription canceled:", response.data);
        this.isCancelLoading = false;
        this.snackbarText = "Your subscription has been cancelled";
        this.snackbar = true;
        await this.init();
      } catch (error) {
        console.error("Error canceling subscription:", error);
        this.snackbarText = "Error on cancelling subscription: " + error;
        this.isCancelLoading = false;
      }
    },
    init() {
      this.retailData = null;
      this.billingGroups = null;
      this.isLoading = true;
      const User = new FormData();
      User.append("username", store.getters.StateRetailUser);
      User.append("password", store.getters.StateRetailPass);
      console.log("user::::", User);

      this.RetailLogin(User).then((res) => {
        if (res.result == "OK") {
          this.retailData = store.getters.StateRetailData;
          this.billingGroups = this.retailData.billing_groups;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },
    formatDate(date) {
      return moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  data() {
    return {
      userData: "",
      billingGroups: null,
      isCancelLoading: false,
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      baseDomain: window.location.origin,
      retailData: null,
    };
  },
};
</script>
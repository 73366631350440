<template>
  <base-layout current="subs">
    <v-snackbar v-model="invalidPromoCodeSnackbar">
      {{ promoErrorText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="error"
          text
          v-bind="attrs"
          @click="invalidPromoCodeSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="subDialog" width="500">
      <v-card class="pa-4">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h5">{{
              promotion_template.description
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Details:</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <ul>
          <li
            v-for="item in promotion_template.coverage"
            style="color: blue"
            :key="item.modality"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title
                  ><b>Product:</b
                  ><span style="text-transform: capitalize">
                    {{ item.product }}</span
                  ></v-list-item-title
                >
                <v-list-item-subtitle>
                  <b>Modality:</b>
                  <span style="text-transform: capitalize">
                    {{ item.modality }}</span
                  >
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <b>Allowed Transaction Count:</b>
                  <span> {{ item.allowed_transaction_count }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </li>

          <li style="color: blue">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><b>Valid For:</b>
                  <span style="text-transform: capitalize">
                    {{ promotion_template.valid_for_days }}</span
                  >
                  Days</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </li>
        </ul>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="subDialog = false">
            Cancel
          </v-btn>
          <v-btn
            class="ma-2"
            :loading="subLoading"
            :disabled="subLoading"
            color="info"
            @click="redeemPromotionCode()"
          >
            Redeem
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="#18739d"
      >
      </v-progress-linear>

      <v-card class="card-main" elevation="0">
        <v-row>
          <v-col cols="8">
            <v-list-item>
              <v-list-item-content>
                <v-subheader class="text-h5">Subscription Details</v-subheader>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!isSubbed">
              <v-list-item-content>
                <v-alert outlined type="info"
                  >You have not subscribed to any plan</v-alert
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content class="pa-5">
                <v-row
                  ><v-col cols="6">
                    <v-card class="pa-4">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-h6">{{
                            applied_promotions.description
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Details:</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <ul>
                        <li
                          v-for="item in applied_promotions.coverage"
                          style="color: blue"
                          :key="item.modality"
                        >
                          <v-list-item three-line>
                            <v-list-item-content>
                              <v-list-item-title
                                ><b>Product:</b
                                ><span style="text-transform: capitalize">
                                  {{ item.product }}</span
                                ></v-list-item-title
                              >
                              <v-list-item-subtitle>
                                <b>Modality:</b>
                                <span style="text-transform: capitalize">
                                  {{ item.modality }}</span
                                >
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                <b>Allowed Transaction Count:</b>
                                <span>
                                  {{ item.allowed_transaction_count }}</span
                                >
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </li>

                        <li style="color: blue">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                ><b>Valid For:</b>
                                <span style="text-transform: capitalize">
                                  {{ applied_promotions.valid_for_days }}</span
                                >
                                Days</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </li>
                      </ul>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          text
                          @click="subDialog = false"
                          :disabled="true"
                        >
                          Cancel Subscription
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col></v-row
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-divider
            class="mt-8"
            style="border-top: dotted 1px; color: #cecece; max-height: 500px"
            light
            vertical
          ></v-divider>
          <v-col cols="4">
            <v-list-item>
              <v-list-item-content>
                <v-subheader class="title">Promotion Code</v-subheader>
              </v-list-item-content>
            </v-list-item>
            <v-text-field
              v-model="promoCode"
              outlined
              label="Enter code here"
              type="text"
            >
              <template v-slot:prepend>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                  </template>
                  Info about promotion codes
                </v-tooltip>
              </template>
              <template v-slot:append>
                <v-fade-transition leave-absolute>
                  <v-progress-circular
                    v-if="loadingPromo"
                    size="24"
                    color="info"
                    indeterminate
                  ></v-progress-circular>
                </v-fade-transition>
              </template>
              <template v-slot:append-outer>
                <v-btn
                  class="reset-text-transform"
                  medium
                  :disabled="promoCode == ''"
                  color="primary"
                  @click="applyCode()"
                >
                  Apply Code
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";

import store from "@/store";
import { mapActions } from "vuex";

export default {
  components: {
    BaseLayout,
  },
  beforeMount() {
    this.getCurrentPlan().then(() => {});
  },
  mounted() {
    this.tableData =
      store.getters.StateCurrentPlan == "trial"
        ? this.tableData_withTrial
        : this.tableData_alt;
  },
  methods: {
    ...mapActions([
      "GetClientPromotions",
      "RedeemPromotionCode",
      "GetPromotionTemplate",
    ]),
    async applyCode() {
      this.loadingPromo = true;

      if (this.subbedPromoCode == this.promoCode) {
        this.promoErrorText = "This promotion code has already been redeemed.";
        this.invalidPromoCodeSnackbar = true;
        this.loadingPromo = false;
      } else {
        this.promotion_template = await this.GetPromotionTemplate(
          this.promoCode
        );
        console.log("promotion temp:", this.promotion_template);
        if (this.promotion_template) {
          this.subDialog = true;
        } else {
          this.promotion_template = "";
          this.promoErrorText =
            "The promo code you entered is invalid. Please try again";
          this.invalidPromoCodeSnackbar = true;
        }
        this.loadingPromo = false;
      }
    },
    async redeemPromotionCode() {
      this.loader = "subLoading";

      if (
        this.applied_promotions.reject_reason == "promotion_already_redeemed"
      ) {
        this.promoErrorText = "This promotion code has already been redeemed.";
        this.invalidPromoCodeSnackbar = true;
      }

      await this.RedeemPromotionCode({
        session_id: store.getters.StateSessionId,
        promotion_code: this.promoCode,
      });

      this.subbedPromoCode = this.promoCode;

      this.applied_promotions = await this.GetPromotionTemplate(
        this.subbedPromoCode
      );
    },
    async getCurrentPlan() {
      this.loading = true;
      try {
        const resp = await this.GetClientPromotions(
          store.getters.StateSessionId
        );
        if (resp.applied_promotions.length == 0) {
          this.isSubbed = false;
        } else {
          this.subbedPromoCode = resp.applied_promotions[0].promotion_code;
          this.applied_promotions = await this.GetPromotionTemplate(
            this.subbedPromoCode
          );
          this.isSubbed = true;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;

        console.log(error);
      }
    },
    changePlan: function (e) {
      this.selectedPlan = e.target.value;
      this.setUpFee = this.tableData_alt[this.selectedPlan].setUpFee;
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => {
        this[l] = false;
        this.isSubbed = true;
        this.subDialog = false;
        this.promoCode = "";
      }, 3000);

      this.loader = null;
    },
  },
  computed: {
    noLicenseSelected() {
      return (
        this.currentPlan == "" ||
        this.currentPlan == "none" ||
        this.currentPlan == "trial"
      );
    },
  },
  data() {
    return {
      loader: null,
      tableData: {},
      loading: true,
      applied_promotions: "",
      subbedPromoCode: "",
      promotion_template: "",
      dialog: false,
      subLoading: false,
      subDialog: false,
      promoCode: "",
      loadingPromo: false,
      contactUsDialog: false,
      okDialog: false,
      isSubbed: false,
      currentSessionDetails: "",
      invalidPromoCodeSnackbar: false,
      promoErrorText: "",
      loadingCancel: false,
      cancelDialog: false,
      baseDomain: window.location.origin,
    };
  },
};
</script>
<style>
.sub-card.v-card.v-sheet.theme--light {
  background-color: #ffffff !important;
  border-left: 5px solid #18739d !important;
}
.activePlan {
  font-weight: bold;
  color: #000;
}
#overlay {
  position: absolute;
  display: grid;
  width: 100%;
  height: 100%;

  background-color: rgba(24, 115, 157, 0.6);
  z-index: 1;
  cursor: pointer;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <v-app :is="layout">
    <v-main>
      <router-view :layout.sync="layout" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      layout: "div",
    };
  },
};
</script>

<style>
@import "./assets/css/styles.scss";
</style>


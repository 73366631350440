<template>
  <v-app style="background: #fafafa">
    <v-container fluid fill-height>
      <v-row justify="center">
        <v-alert
          dense
          v-if="showSuccess"
          class="login-alert"
          outlined
          text
          type="success"
          >Instructions sent to
          <b>{{ username }}</b>
        </v-alert>

        <v-alert
          dense
          v-if="showError"
          class="login-alert"
          outlined
          text
          type="error"
        >
          {{ errorMsg }}
        </v-alert>
        <v-col cols="12">
          <v-row justify="center"> </v-row>
          <v-card
            elevation="0"
            class="login-card"
            v-bind:style="showSuccess ? 'height: 75%;' : ''"
          >
            <v-row justify="center" class="py-8"
              ><img
                class="justify-center"
                :src="require('@/assets/images/privlogo.png')"
                alt="logo"
            /></v-row>
            <v-row justify="center"
              ><v-col cols="11"
                ><span v-if="!showSuccess"
                  >To reset your password, enter your email address below and
                  follow the instructions sent to your inbox</span
                >
                <span v-if="showSuccess"
                  >Follow the instructions to reset your password and log in to
                  your Privately Dashboard.</span
                >
              </v-col></v-row
            >
            <v-form ref="form">
              <v-card-text>
                <v-text-field
                  v-if="!showSuccess"
                  outlined
                  dense
                  background-color="#FAFAFA"
                  class="rounded-0"
                  ref="username"
                  v-model="username"
                  label="Email Address"
                  :rules="emailRules"
                  required
                  @keyup.enter="submit"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#18739d"
                  text
                  :ripple="false"
                  plain
                  style="
                    text-transform: unset !important;
                    opacity: 1 !important;
                  "
                  :to="login_route"
                  ><v-icon>mdi-arrow-left</v-icon>Back to Login</v-btn
                >
                <v-spacer></v-spacer>

                <v-btn
                  style="font-size: 15px !important; width: 150px !important"
                  :loading="isLoading"
                  elevation="0"
                  v-if="!showSuccess"
                  :ripple="false"
                  class="login-button white--text"
                  @click="submit"
                  :disabled="!(username != '' && reg.test(username))"
                >
                  Reset Password
                </v-btn>
              </v-card-actions>
            </v-form>

            <v-spacer></v-spacer>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-footer absolute>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="(link, index) in links"
          elevation="0"
          :key="index"
          color="#18739d"
          text
          dark
          link
          :target="!link.mailto ? '_blank' : ''"
          :href="link.link"
          :ripple="false"
          rounded
          class="my-2 rounded-0"
        >
          {{ link.title }}
        </v-btn>
        <v-col
          class="py-4 text-center black--text font-weight-light subtitle-2"
          cols="12"
        >
          © Copyright 2024 Privately - All Rights Reserved
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import jwt from "jsonwebtoken";
import axios from "axios";

const SECRET = "2msglh0vaaqogn96fig5d5ohfk6v0932dremjhgr5ad5gmg0d7t";

export default {
  name: "ForgottenPassword",
  data() {
    return {
      links: [
        {
          title: "Privacy Policy",
          link: "https://www.privately.eu/legal",
        },
        {
          title: "Terms and Conditions",
          link: "/static/docs/terms_and_conditions.pdf",
        },
        {
          title: "Contact Us",
          link: "mailto:contact@privately.eu",
          mailto: true,
        },
      ],
      emailEmptyErr: "Please enter your email address",
      emailInvalidErr: "Not a valid email address",
      emailErr: "",
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

      username: "",
      invalidEmail: false,
      login_route: "/login",
      emailFound: false,
      showError: false,
      errorMsg: "An unknown exception has occured",
      isLoading: false,
      showSuccess: false,
      baseDomain: window.location.origin,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  created() {},
  methods: {
    async submit() {
      this.isLoading = true;
      if (this.validateEmail()) {
        const User = new FormData();
        User.append("username", this.username);

        await axios
          .post(
            "https://vu3xcwlw3b.execute-api.eu-west-1.amazonaws.com/default/age-dashboard-change-password",
            {
              execution_mode: "check_email",
              email: this.username,
            },
            { headers: { "Content-Type": "application/json" } }
          )
          .then((resp) => {
            if (resp.data.result == "DENY") {
              this.showError = true;
              this.errorMsg = "Email not found";
              this.emailFound = false;
              this.isLoading = false;
            } else {
              this.emailFound = true;
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.showError = true;
            this.errorMsg = "An unknown exception occurred";
            console.log(err);
          });

        if (this.emailFound) {
          try {
            const payload = {
              email: this.username,
            };

            const token = jwt.sign(payload, SECRET, { expiresIn: "15m" });
            axios
              .post(
                "https://lzc6zaxb7j.execute-api.eu-west-1.amazonaws.com/default/ses-email-test",
                null,
                {
                  params: {
                    token,
                    email: this.username,
                    domain: this.baseDomain,
                  },
                }
              )
              .then(() => {
                this.showSuccess = true;
                this.showError = false;
                this.isLoading = false;
              })
              .catch((err) => {
                this.isLoading = false;
                return err;
              });
          } catch (error) {
            this.showError = true;
            this.isLoading = false;
          }
        }
      } else {
        this.isLoading = false;
        return false;
      }
    },
    validateEmail() {
      if (this.username === "") {
        this.invalidEmail = true;
        this.emailErr = this.emailEmptyErr;
        this.showError = false;
        return false;
      } else if (!this.reg.test(this.username)) {
        this.invalidEmail = true;
        this.emailErr = this.emailInvalidErr;
        this.showError = false;
        return false;
      } else {
        this.invalidEmail = false;
        return true;
      }
    },
  },
};
</script>

<template>
  <v-container class="py-15">
    <v-row justify="center">
      <v-icon x-large>mdi-folder-open-outline</v-icon>
    </v-row>
    <v-row justify="center">
      <span class="text-h6 font-weight-light">No data</span>
    </v-row>
    <v-spacer></v-spacer>
  </v-container>
</template>

<script>
export default {};
</script>

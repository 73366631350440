<template>
  <retail-base-layout current="licenceList">
    <v-app style="background: #fafafa">
      <v-container>
        <v-card class="card-main" elevation="0">
          <v-container fill-height fluid>
            <v-row class="ma-4" justify="center" align="center">
              <v-col>
                <EstimationCard
                  :loading="isLoading"
                  metric-title="Total number of licences"
                  :metricValue="getTotalNumberOfLicences"
              /></v-col>
              <v-col>
                <EstimationCard
                  :loading="isLoading"
                  metric-title="Active"
                  :metricValue="numberOfActivatedLicences"
              /></v-col>

              <v-col>
                <EstimationCard
                  :loading="isLoading"
                  metric-title="Inactive"
                  :metricValue="numberOfInactiveLicences"
              /></v-col> </v-row
          ></v-container>

          <v-container>
            <v-data-table
              :loading="isLoading"
              loading-text="Loading... Please wait"
              :headers="getHeadersDynamically"
              :items="flattenedList"
              :items-per-page="10"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:[`item.location`]="{ item }">
                <v-edit-dialog
                  @save="updateLocation(item)"
                  :return-value.sync="item.location"
                  large
                  v-model="isEditDialogVisible"
                >
                  {{ item.location }}
                  <v-text-field
                    v-model="item.location"
                    slot="input"
                    :rules="[(v) => !!v || 'Location cannot be empty']"
                    label="Location"
                    single-line
                  ></v-text-field>
                </v-edit-dialog>
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-toolbar-title class="text-h5 font-weight-medium"
                      >List of licences
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field
                      style="max-width: 450px"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      :options.sync="options"
                      :server-items-length="totalNumberOfLicences"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-toolbar>
              </template>

              <template v-slot:[`item.activated_at_utc`]="{ item }">
                <div v-if="item.deactivated_at_utc == null">
                  <v-tooltip
                    v-if="
                      item.activated_at_utc && item.deactivated_at_utc == null
                    "
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-chip
                          :color="!item.activated_at_utc ? 'blue' : 'green'"
                          dark
                        >
                          {{
                            item.activated_at_utc
                              ? "Activated"
                              : "Not activated"
                          }}
                        </v-chip></span
                      >
                    </template>
                    <span>at {{ item.activated_at_utc }}</span>
                  </v-tooltip>
                  <v-chip
                    v-else
                    :color="!item.activated_at_utc ? 'blue' : 'green'"
                    dark
                  >
                    {{ item.activated_at_utc ? "Activated" : "Not activated" }}
                  </v-chip>
                </div>
                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-chip color="red" dark> Deactivated </v-chip></span
                      >
                    </template>
                    <span>at {{ item.deactivated_at_utc }}</span>
                  </v-tooltip>
                </div>
              </template>

              <template v-slot:[`item.deeplink_data`]="{ item }">
                <div style="display: flex; align-items: center">
                  <v-btn
                    class="reset-text-transform mt-2"
                    color="black"
                    plain
                    :disabled="
                      item.activated_at_utc != false ||
                      item.deactivated_at_utc != null
                    "
                    @click="onQRClicked(item)"
                    text
                  >
                    <v-icon dense>mdi-qrcode</v-icon>
                  </v-btn>
                  <v-divider inset vertical></v-divider>

                  <v-btn
                    class="reset-text-transform mt-2"
                    color="black"
                    :disabled="
                      item.activated_at_utc != false ||
                      item.deactivated_at_utc != null
                    "
                    plain
                    :href="
                      'https://developer.privately.eu/retail-app-activation/' +
                      item.deeplink_data
                    "
                    target="_blank"
                    text
                  >
                    <v-icon dense>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
            <v-snackbar
              :timeout="snackbarTimeout"
              :value="false"
              absolute
              top
              :color="!errorOccurred ? 'success' : 'red'"
              v-model="snackbar"
            >
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  class="reset-text-transform"
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </v-container>
        </v-card>
        <v-dialog v-model="dialogForQRCode" width="350">
          <v-card class="justify-center">
            <v-card-title class="subtitle-1 grey lighten-2">
              <span class="font-weight-bold">Licence code:&nbsp;</span>
              {{ selectedLicenceCode }} <br />
              <span
                style="
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                "
                class="subtitle-1"
                >Please scan the QR code below to activate your
                <span class="font-weight-bold">AgeAI for Retail</span>
                licence</span
              >
            </v-card-title>
            <v-container fill-height>
              <v-row justify="center" align="center"
                ><v-col>
                  <v-card-text>
                    <qrcode-vue
                      :value="
                        'https://developer.privately.eu/retail-app-activation/' +
                        selectedDeeplinkData
                      "
                      :size="300"
                      level="H"
                    /> </v-card-text
                ></v-col> </v-row
            ></v-container>
          </v-card>
        </v-dialog>
      </v-container> </v-app
  ></retail-base-layout>
</template>

<script>
import { mapActions } from "vuex";
import EstimationCard from "@/components/cards/EstimationCard.vue";
import QrcodeVue from "qrcode.vue";
import store from "@/store";
import RetailBaseLayout from "@/layouts/RetailBaseLayout.vue";

export default {
  created() {
    if (store.getters.StateRetailData == null) {
      this.$router.push("/retail-login");
    } else {
      this.retailData = store.getters.StateRetailData;
    }
  },
  mounted() {
    this.init();
  },
  components: {
    EstimationCard,
    QrcodeVue,
    RetailBaseLayout,
  },
  computed: {
    getTotalNumberOfLicences: function () {
      return this.totalNumberOfLicences;
    },
    getHeadersDynamically: function () {
      let headers = [
        {
          text: "Licence code",
          align: "start",
          sortable: false,
          value: "licence_code",
        },
        { text: "Created at", value: "licence_created_at_utc" },
        { text: "Status", value: "activated_at_utc" },
        { text: "Requested by", value: "requested_by" },
        { text: "Subclient", value: "subclient" },
        { text: "Location", value: "location" },
        { text: "QR Code | Deeplink", sortable: false, value: "deeplink_data" },
      ];

      if (this.selfService) {
        headers.pop();
        headers.push({ text: "Billed to", value: "billed_to_email" });
      }

      return headers;
    },
  },
  methods: {
    ...mapActions(["UpdateLicenceDetails", "RetailLogin"]),
    onQRClicked(item) {
      this.dialogForQRCode = true;
      this.selectedDeeplinkData = item["deeplink_data"];
      this.selectedLicenceCode = item["licence_code"];
      //start polling for activation
      //this.pollForLicenceActivation();
    },
    flattenObject(obj) {
      const flattened = {};

      Object.keys(obj).forEach((key) => {
        const value = obj[key];

        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          Object.assign(flattened, this.flattenObject(value));
        } else {
          flattened[key] = value;
        }
      });

      return flattened;
    },
    updateLocation(item) {
      if (item.location != "") {
        this.UpdateLicenceDetails({
          deactivated_at_utc: null,
          location: item.location,
          deeplink_data: item.deeplink_data,
        }).then((res) => {
          if (res) {
            console.log("OK");
          }
        });
      } else {
        this.isEditDialogVisible = true;
        //event.preventDefault();
        return;
      }
    },
    pollForLicenceActivation() {
      const pollInterval = 2000;
      const maxPollAttempts = 15;

      let pollCount = 0;
      const poll = setInterval(() => {
        pollCount++;
        this.ListLicencesByBillingGroupId({
          billing_group_id: this.token,
        })
          .then((response) => {
            if (response.msg === "OK") {
              const updatedLicence = response.licences.find(
                (licence) => licence.licence_code === this.selectedLicenceCode
              );

              if (updatedLicence && updatedLicence.activated_at_utc) {
                clearInterval(poll);
                this.isQRScanned = false;
                this.dialogForQRCode = false;
                this.licenceList = response.licences;
                this.init();
                this.snackbar = true;
                this.text = `Licence with code ${this.selectedLicenceCode} has been activated successfully`;
              }

              if (pollCount >= maxPollAttempts || !this.dialogForQRCode) {
                clearInterval(poll);
                this.isQRScanned = false;
                this.dialogForQRCode = false;
              }
            }
          })
          .catch((error) => {
            clearInterval(poll);
            console.error("Error occurred during polling: ", error);
          });
      }, pollInterval);
    },
    init() {
      this.isLoading = true;

      const User = new FormData();
      User.append("username", store.getters.StateRetailUser);
      User.append("password", store.getters.StateRetailPass);
      console.log("user::::", User);
      this.RetailLogin(User).then((res) => {
        if (res.result == "OK") {
          this.licenceList = this.retailData.licences;
          this.numberOfActivatedLicences = this.licenceList.filter(
            (licence) => licence.activated_at_utc != false
          ).length;
          this.totalNumberOfLicences = this.licenceList.length;
          this.numberOfInactiveLicences =
            this.totalNumberOfLicences - this.numberOfActivatedLicences;
          for (let licence of this.licenceList) {
            this.flattenedList.push(this.flattenObject(licence));
          }
          this.isLoading = false;
        }
      });
    },
  },
  watch: {
    options: {
      handler() {
        this.init();
      },
      deep: false,
    },
  },

  data() {
    return {
      options: {},
      search: "",

      token: this.$route.params.token,
      isLoading: false,
      licenceList: [],
      item: null,
      snackbar: false,
      snackbarTimeout: 5000,
      isEditDialogVisible: false,
      flattenedList: [],
      retailData: null,
      numberOfLicences: 0,
      numberOfActivatedLicences: 0,
      numberOfInactiveLicences: 0,
      totalNumberOfLicences: 0,
      errorOccurred: false,
      dialogForQRCode: false,
      selectedLicenceCode: "",
      selectedDeeplinkData: "",
      text: " licence(s) have been generated successfully!",

      links: [
        {
          title: "Privacy Policy",
          link: "/static/docs/privacy_policy.pdf",
        },
        {
          title: "Terms and Conditions",
          link: "/static/docs/terms_and_conditions.pdf",
        },
        {
          title: "Contact Us",
          link: "mailto:contact@privately.eu",
          mailto: true,
        },
      ],
    };
  },
};
</script>

<template>
  <base-layout current="reports">
    <span class="text-h4">Reports</span>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
export default {
  components: {
    BaseLayout,
  },
};
</script>
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueCarousel from "vue-carousel";
import Vuelidate from "vuelidate";
import VueApexCharts from "vue-apexcharts";
import router from "./router/router";
import store from "./store";
import axios from "axios";
//import { StripePlugin } from '@vue-stripe/vue-stripe';
import { Plugin } from "vue-responsive-video-background-player";
import QrcodeVue from "qrcode.vue";

/*const options = {
  pk: "pk_test_51L6ZyXF8wteQE7fZyCWrVnzm8VYvZkHQ10462Lrd2D19bbrpIyn48XUoOubXVojEKHqjzYcJvJWRhfPqdVUunD5k00jj9wwdqe",
 
};

Vue.use(StripePlugin, options);*/

Vue.use(Vuelidate);

Vue.use(VueApexCharts);
Vue.use(Plugin);
Vue.use(QrcodeVue);

Vue.config.productionTip = false;
Vue.use(VueCarousel);

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.withCredentials = false;

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push("/login");
    }
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
